export const API_KEY = process.env.NEXT_PUBLIC_API_KEY!
export const STRIPE_KEY = process.env.NEXT_PUBLIC_STRIPE_KEY!
export const VERCEL_ENV = process.env.VERCEL_ENV!
export const PUBLIC_VERCEL_ENV = process.env.NEXT_PUBLIC_VERCEL_ENV!
export const GTM_CONTAINER_ID = 'GTM-MST3BC93'
export const INTERCOM_APP_ID = 'zdn94znx'

export const APPLE_CLIENT_ID = process.env.NEXT_PUBLIC_APPLE_CLIENT_ID!
export const GOOGLE_CLIENT_ID = process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID!

export const getEnvironment = () => {
  if (PUBLIC_VERCEL_ENV === 'production' || VERCEL_ENV === 'production')
    return 'production'
  return 'development'
}

export const environment =
  getEnvironment() !== 'production' ? 'development' : 'production'

export const isDevelopment = environment === 'development'

export const API_URL =
  getEnvironment() === 'production'
    ? 'https://api.brick.tech'
    : 'https://dev-api.brick.tech'

export const getOrigin = () => {
  if (typeof window !== 'undefined') {
    return window.location.origin
  }
  if (VERCEL_ENV === 'preview') {
    return 'https://web-dev.brick.tech'
  }
  if (VERCEL_ENV === 'production') {
    return 'https://web.brick.tech'
  }
  return 'http://localhost:3000'
}

export const GOGGLE_REDIRECT_URI = `${API_URL}/auth/callback`
export const APPLE_REDIRECT_URI = getOrigin()
export const APP_VERSION = 'WEB:2.0.0'

export const LANGUAGES = [
  { value: 'en', label: 'English' },
  { value: 'sv', label: 'Swedish' },
  { value: 'cs', label: 'Czech' },
  { value: 'de', label: 'German' },
  { value: 'es', label: 'Spanish' },
  { value: 'fr', label: 'French' },
  { value: 'hr', label: 'Croatian' },
  { value: 'hu', label: 'Hungarian' },
  { value: 'it', label: 'Italian' },
  { value: 'iv', label: 'Latvian' },
  { value: 'ka', label: 'Georgian' },
  { value: 'mk', label: 'Macedonian' },
  { value: 'nl', label: 'Dutch' },
  { value: 'pl', label: 'Polish' },
  { value: 'pt', label: 'Portuguese' },
  { value: 'ro', label: 'Romanian' },
  { value: 'ru', label: 'Russian' },
  { value: 'sk', label: 'Slovak' },
  { value: 'sr', label: 'Serbian' },
  { value: 'tr', label: 'Turkish' },
] as const

export const Links = {
  CONTACT_EMAIL: 'mailto:help@brickapp.se',
  TERMS: 'https://www.brick.tech/terms',
  IOS_LINK:
    'https://apps.apple.com/us/app/brick-powerbank-sharing/id1471658723',
  ANDROID_LINK: 'https://play.google.com/store/apps/details?id=app.qvik',
  STORE_LINK: 'https://onelink.to/gs8tj3',
}

const constants = {
  MAX_RENTALS: 2,
  MAX_WEEKLY_RENTALS: 10,
} as const

export default constants
