'use client'

import { FC, useEffect } from 'react'

import Error from 'next/error'

import { getEnvironment } from '@/lib/constants'
import * as Sentry from '@sentry/nextjs'

type Props = {
  error: Error
}

const GlobalError: FC<Props> = ({ error }) => {
  useEffect(() => {
    if (getEnvironment() === 'production') {
      Sentry.captureException(error)
    }
  }, [error])
  console.log('GlobalError', error)
  return (
    <html>
      <body>
        <Error
          statusCode={error?.props?.statusCode}
          title={error?.props?.title}
        />
      </body>
    </html>
  )
}

export default GlobalError
